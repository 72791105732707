import { Directive, HostBinding, Input, OnInit } from "@angular/core";

@Directive({
  selector: 'img',
  host: {
    '(error)': 'useFallbackImage()',
    '[src]': 'src',
    '(load)': 'load()'
  },
  standalone: true
})
export class ImageDirective implements OnInit {
  @Input() src?: string;
  @HostBinding('class') className?: string;

  ngOnInit(): void {
    if (!this.src) {
      this.useFallbackImage();
    }
  }

  useFallbackImage(): void {
    this.src = 'assets/download.png';
  }

  load() {
    this.className = 'image-loaded';
  }

}
